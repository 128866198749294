.navbar {
    background-color: #282c34;
    padding: 10px 20px;
  }
  
  .navbar .logo {
    color: white;
  }
  
  .navbar nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar nav ul li {
    margin-right: 16px;
  }
  
  .navbar nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .navbar nav ul li a:hover {
    text-decoration: underline;
  }
  